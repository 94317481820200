import React from 'react';
import Layout from '../components/Layout';
import Experience from '../components/Experience';
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContentContext from "../context/ContentContext";
import { useSiteMetadata } from "../hooks/use-site-metadata"
import favicon from "../../static/favicon.svg"
import { useLocation } from "@reach/router";
import { setPathCanonical } from "../components/lib/utils";

export const query = graphql`
	query ExperienceDetails($lang: String, $experienceId: Int) {
		experience(lang: {eq: $lang}, experienceId: {eq: $experienceId}) {
			canonical_url
			name
	        coordinates {
	          lat
	          lng
	        }
	        difficulty
	        experienceId
	        duration {
	          formatted
	        }
	        images {
	          alt
	          remoteImage {
  				childImageSharp {
  					fixed(width: 655, height:311, fit: COVER, cropFocus: CENTER) {
  						src
						srcSet
  					}
  				}
			  }
	          title
	        }
	        originalPath
	        lowestRate {
	          formatted
	          currency
	        }
	        languages
	        information {
	          body
	          title
	        }
	        description {
	          summaryBlock {
	            content
	            title
	          }
	          description
	        }
	        seo {
						title
						description
					}
	        products {
	          description {
	            description
	            summaryBlock {
	              content
	              title
	            }
	          }
	          lowestRate {
	            currency
	            formatted
	          }
	          id
	          name
	          weekDays
	          languages
	        }
			translations {
				lang
				path
			}
	  }
	}
`

export default ( { data } ) => {
	const experience = data.experience;
  const { title, siteUrl } = useSiteMetadata();
	const htmlTitle = experience.name + " | " + title;
	const location = useLocation();

	return (
		<ContentContext.Provider value={{ translations: experience.translations }}>
			<Layout>
				<Helmet>
					<title>{htmlTitle}</title>
					<link rel="icon" href={favicon} />
					<meta name="title" content={htmlTitle} />
					<meta name="description" content={experience.seo?.description} />
					<meta name="og:title" content={experience.seo?.title} />
					<meta name="og:description" content={experience.seo?.description} />
					<meta name="og:type" content="product" />
					<meta name="og:url" content={setPathCanonical(location.href)} />
					{experience.translations.map((translation) =>
						<link rel="alternate" key={translation.lang} hrefLang={translation.lang} href={siteUrl  + translation.path + '/'} />)
					}
					<link rel="canonical" href={setPathCanonical(location.href)} />
					<body className={`experience`} data-spy="scroll" data-target="#submenu"/>
				</Helmet>
				<Experience experience={experience}/>
			</Layout>
		</ContentContext.Provider>);
}
