import React, { Component } from "react"
import parse from 'html-react-parser';
import { withTranslation, Link } from 'gatsby-plugin-react-i18next';
import Product from "./Product";
import InformationPanel from "./InformationPanel";
import Carousel from "./Carousel";
import CardInfo from "./CardInfo";
import { parseCurrency } from './lib/utils'

class Experience extends Component {

	constructor(props) {
		super(props);
		this.state = {
			toggleHiddenElements: false
		}
	}

	componentDidMount() {
		if (typeof window !== `undefined`) {
			window.$('body').scrollspy({ target: '#submenu' });

			this.toggleHiddenElements();
			
			window.$(window).scroll(function() {
				this.toggleHiddenElements();
			}.bind(this));
		}
	}

	/**
	 * Método para mostrar u ocultar elementos que solamente aparecen al hacer scroll
	 */
	toggleHiddenElements() {
		if(window.$(".section-contextual-menu").offset() !== undefined) {
			if ((window.$(".section-contextual-menu").offset().top > 650 && window.$(window).width() >= 992) || 
				(window.$(".section-contextual-menu").offset().top > 1000 && window.$(window).width() < 992)) {
				this.setState({toggleHiddenElements: true});
			} else {
				this.setState({toggleHiddenElements: false});
			}
		}
	}

	/**
	 * Método para construir un JSON con las secciones del Card-Info
	 * @param {Function} t : Función de traducciones
	 */
	buildCardInfoSections(t) {
		
		return [
			{
				"title":	<>
								<svg width="1em" height="1em" viewBox="0 0 16 16" className={`bi bi-globe align-text-top`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path fillRule={`evenodd`} d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4H2.255a7.025 7.025 0 0 1 3.072-2.472 6.7 6.7 0 0 0-.597.933c-.247.464-.462.98-.64 1.539zm-.582 3.5h-2.49c.062-.89.291-1.733.656-2.5H3.82a13.652 13.652 0 0 0-.312 2.5zM4.847 5H7.5v2.5H4.51A12.5 12.5 0 0 1 4.846 5zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5H7.5V11H4.847a12.5 12.5 0 0 1-.338-2.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12H7.5v2.923c-.67-.204-1.335-.82-1.887-1.855A7.97 7.97 0 0 1 5.145 12zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11H1.674a6.958 6.958 0 0 1-.656-2.5h2.49c.03.877.138 1.718.312 2.5zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12h2.355a7.967 7.967 0 0 1-.468 1.068c-.552 1.035-1.218 1.65-1.887 1.855V12zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5h-2.49A13.65 13.65 0 0 0 12.18 5h2.146c.365.767.594 1.61.656 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4H8.5V1.077c.67.204 1.335.82 1.887 1.855.173.324.33.682.468 1.068z" />
								</svg>
								&nbsp;
								{t("Idiomas")}
							</>,
				"body": this.props.experience.languages.map((language, i) => [
							i > 0 && " - ",
							language.toUpperCase()
						]),
				"highlight": false,
				"button": null
			},
			{
				"title":	<>
								<svg width="1em" height="1em" viewBox="0 0 16 16" className={`bi bi-clock align-text-top`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path fillRule={`evenodd`} d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z" />
									<path fillRule={`evenodd`} d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
								</svg>
								&nbsp;
								{t("Duración aprox.")}
							</>,
				"body": this.props.experience.duration.formatted,
				"highlight": false,
				"button": null
			},
			{
				"title": t("Dificultad"),
				"body": t(this.props.experience.difficulty),
				"highlight": false,
				"button": null
			}
			,{
				"title": t("Desde"),
				"body": this.props.experience.lowestRate.formatted + parseCurrency(this.props.experience.lowestRate.currency),
				"highlight": true,
				"button": 	
							<React.Fragment>	
								<Link key="main-experience" to={`/booking/experience/${this.props.experience.experienceId}/`}
											rel={'nofollow'}>
									<button className={`mb-2 btn btn-primary btn-block`} rel="nofollow">
										<span>{t("Comprar ahora")}</span>
										&nbsp;
										<svg width="1em" height="1em" viewBox="0 0 16 16" className={`bi bi-chevron-right`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fillRule={`evenodd`} d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
										</svg>
									</button>
								</Link>	
								<Link key="secondary-experience" to={`/booking/experience/735/` }
											rel={'nofollow'}>
									<button className={`btn btn-outline-primary btn-block`} rel="nofollow">
										<span>{t("Reservas federados")}</span>
										&nbsp;
										<svg width="1em" height="1em" viewBox="0 0 16 16" className={`bi bi-chevron-right`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fillRule={`evenodd`} d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
										</svg>
									</button>
								</Link>																
							</React.Fragment>
			}
		];
	}

	render() {
		const { t } = this.props;
		let sections = this.buildCardInfoSections(t);
		
		return 	<React.Fragment>
					<div className={`articulo page-experiencia`}>

						<div className={`row pt-5`}>
							<div className={`col-12`}>
								<h2>{this.props.experience.name}</h2>
							</div>
						</div>
						<div className={`row`}>
							<Carousel classes={`col-12 col-lg-9 p-3 mb-2`} images={this.props.experience.images}></Carousel>
							<CardInfo classes={`col-12 col-lg-3 p-3 mb-2`} sections={sections}></CardInfo>
						</div>

						{/* Navbar y elementos sticky */}
						<div className={`row sticky-top pt-2 bg-white`}>
							{this.state.toggleHiddenElements &&
								<div className={`col-12`}>
									<h2>{this.props.experience.name}</h2>
								</div>
							}
						</div>
						<nav className={`row navbar navbar-default section-contextual-menu sticky-top`}>
							<div className={`container-fluid d-none d-lg-block`}>
								<div id="submenu" className={`navbar-container navbar-collapse no-padding`} aria-expanded="false">
									<ul className={`nav navbar-nav`}>
										<li className={`nav-item`}>
											<a className={`menu-collapse nav-link active`} href="#descripcion">{t("DESCRIPCIÓN")}</a>
										</li>
										<li className={`nav-item`}>
											<a className={`menu-collapse nav-link`} href="#reservar">{t("RESERVAR")}</a>
										</li>
										<li className={`nav-item`}>
											<a className={`menu-collapse nav-link`} href="#informacion">{t("+ INFORMACIÓN")}</a>
										</li>
										<li className={`nav-item`}>
											<a className={`menu-collapse nav-link`} href="#como-llegar">{t("¿DÓNDE ESTAMOS?")}</a>
										</li>
									</ul>
								</div>
							</div>
						</nav>

						<hr id="experience-page-content-start"></hr>
						<div className={`pt-3 text-center`}>
							<h2 id="descripcion">{t("DESCRIPCIÓN")}</h2>
						</div>
						<div className={`pt-4 pb-3 text-justify`}>
							{parse(this.props.experience.description.description, {})}
						</div>
						<div className={`masca destacado destacado-verde`}>
							<div className={`row`}>
								<div className={`col-12 col-sm-6`}>
									<span>{parse(this.props.experience.description.summaryBlock[0].title, {})}</span>
									<div>{parse(this.props.experience.description.summaryBlock[0].content, {})}</div>
								</div>
								<div className={`col-12 col-sm-6`}>
									<span>{parse(this.props.experience.description.summaryBlock[1].title, {})}</span>
									<div>{parse(this.props.experience.description.summaryBlock[1].content, {})}</div>
									<span>{parse(this.props.experience.description.summaryBlock[2].title, {})}</span>
									<div>{parse(this.props.experience.description.summaryBlock[2].content, {})}</div>
								</div>
							</div>
						</div>
						<br></br>
						<hr></hr>
						<div className={`pt-3 pb-4 text-center`}>
							<h2 id="reservar">{t("RESERVAR")}</h2>
						</div>
						{this.props.experience.products.map((product, i) => {
							return (<Product key={i} product={product} id={`product_` + i} experienceId={this.props.experience.experienceId}/>)
						})}
						<br></br>
						<hr></hr>
						<div className={`pt-3 pb-4 text-center`}>
							<h2 id="informacion">{t("+ INFORMACIÓN")}</h2>
						</div>
						{this.props.experience.information.map((item, i) => {
							return (<InformationPanel key={`ìp` + i} title={item.title} body={item.body} id={`information_` + i} />)
						})}
						<br></br>
						<hr></hr>
						<div className={`pt-3 pb-4 text-center`}>
							<h2 id="como-llegar">{t("¿DÓNDE ESTAMOS?")}</h2>
						</div>
						<InformationPanel id={`como-llegar_1`} title={t("Localización")} showMap={true} coordinates={this.props.experience.coordinates}/>
						{/* Botones sticky-bottom para la vista móvil */}
						<div className={`row sticky-bottom pb-2 bg-white d-xs-block d-lg-none`}>
							{this.state.toggleHiddenElements &&
								<>
									<div className={`col-6`}>
										<a href="#informacion">
											<button className={`btn btn-primary btn-block`}>
												<span>{t("+ Info")}</span>
											</button>
										</a>
									</div>
									<div className={`col-6`}>
										<Link to={`/booking/experience/${this.props.experience.experienceId}/`}
													rel={'nofollow'}>
											<button className={`btn btn-primary btn-block`} rel="nofollow">
												<span>{t("Reservar ya")}</span>
											</button>
										</Link>
									</div>
								</>
							}
						</div>
					</div>
				</React.Fragment>;
	}
}

export default withTranslation('Experience')(Experience);
